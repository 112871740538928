import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./_leaves.scss";
import NegotiationDetails from "./NegotiationDetails";
import Button from "../../components/Button";
import SubTitle from "../../components/SubTitle/SubTitle.jsx";
import EmployeeLeaveDetails from "./EmployeeLeaveDetails";
import Notice from "../../components/Notice";
import "react-toastify/dist/ReactToastify.css";
import { applyForNegotiation } from "../../slices/leave";
import { useDispatch } from "react-redux";

function ApplyForNegotiationForm(props) {
  const dispatch = useDispatch();
  const { handleClose, id, data } = props;

  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });

  useEffect(() => {
    setTimeout(() => {
      setNotice((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 6000);
  }, [notice]);

  const initialValues = {
    negotiationDate: {
      from: data.row
        ? null
        : data
        ? {
            day: new Date(data.negotiationStartDate).getDate(),
            month: new Date(data.negotiationStartDate).getMonth() + 1,
            year: new Date(data.negotiationStartDate).getFullYear(),
          }
        : null,

      to: data.row
        ? null
        : data
        ? {
            day: new Date(data.negotiationEndDate).getDate(),
            month: new Date(data.negotiationEndDate).getMonth() + 1,
            year: new Date(data.negotiationEndDate).getFullYear(),
          }
        : null,
    },
    negotiationDescription: data?.row ? "" : data?.negotiationDescription,
  };

  const validationSchema = Yup.object({
    negotiationDescription: Yup.string()
      .min(10, "Negotiation Description must be at least 10 characters")
      .required("Negotiation description is required!"),
  });
  const onSubmit = async (values, submitProps) => {
    const Data = {
      id: data?.row?._id,
      negotiationStartDate:
        values.negotiationDate?.from?.month +
        "/" +
        values.negotiationDate?.from?.day +
        "/" +
        values.negotiationDate?.from?.year,
      negotiationEndDate:
        values.negotiationDate?.to?.month +
        "/" +
        values.negotiationDate?.to?.day +
        "/" +
        values.negotiationDate?.to?.year,
      negotiationDescription: values.negotiationDescription,
    };

    await dispatch(applyForNegotiation(Data)).then((res) => {
      if (res?.error) {
        setNotice({
          text: res?.error?.message,
          open: true,
          state: "error",
        });
        submitProps.setStatus({ success: false });
        submitProps.setSubmitting(true);
      } else {
        if (res?.payload?.message) {
          setNotice({
            text: res?.payload?.message,
            open: true,
            state: "success",
          });
          submitProps.setStatus({ success: true });
          submitProps.setSubmitting(true);
          setTimeout(() => {
            handleClose(id);
          }, 1500);
          data?.getLeaveStatus({
            id: 4,
            code: ["waiting-employee-negotiate"],
            name: "Negotiation",
          });
        } else {
        }
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <>
            <Notice
              open={notice.open}
              text={notice.text}
              state={notice.state}
            />
            <div
              className={
                data.row
                  ? "negotiation-form-wrapper"
                  : "negotiation-form-wrapper-details negotiation-form-wrapper"
              }
            >
              <SubTitle
                subTitle={"Employee Leave Details"}
                className="subTitle_container  blueColor size"
              />

              <EmployeeLeaveDetails data={data?.row ? data?.row : data} />
              <SubTitle
                subTitle={"Negotiation Details"}
                className="subTitle_container  blueColor size"
              />
              <Form>
                <NegotiationDetails
                  formik={formik}
                  disabled={data?.row ? false : true}
                />

                {data?.row && (
                  <div className="ta-btns-group " style={{ marginTop: "21px" }}>
                    <Button
                      text="Save"
                      type="submit"
                      className={
                        !formik.isValid || formik.isSubmitting
                          ? " blue-disabled-button "
                          : "blueButton "
                      }
                    />

                    <Button className="whiteButton" text="Reset" type="reset" />
                  </div>
                )}
              </Form>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

export default ApplyForNegotiationForm;
